import { gql } from "urql";

export const LoginGQL = gql`
  mutation Login($input: LoginRequest!) {
    login(input: $input) {
      refresh_token
      refresh_expiry
      access_token
      access_expiry
      user {
        id
      }
    }
  }
`;

export const SignupByPhoneNumber = gql`
  mutation SignupByPhoneNumber($input: SignUpRequest!) {
    signupByPhoneNumber(input: $input)
  }
`;

export const SendForgotPasswordOTP = gql`
  mutation SendForgetPasswordOTP($phone: String!) {
    sendForgetPasswordOTP(phone: $phone)
  }
`;

export const ResetPassword = gql`
  mutation ResetPassword($otp: String!, $phone: String!, $password: String!) {
    resetPassword(OTP: $otp, phone: $phone, password: $password)
  }
`;

export const ResendVerifyPhoneOTP = gql`
  mutation ResendVerifyPhoneOTP($phone: String!) {
    resendVerifyPhoneOTP(phone: $phone)
  }
`;

export const VerifyPhone = gql`
  mutation VerifyPhone($otp: String!, $phone: String!) {
    verifyPhone(otp: $otp, phone: $phone) {
      refresh_token
      refresh_expiry
      access_token
      access_expiry
      user {
        id
      }
    }
  }
`;
