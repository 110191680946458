import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { AnimatePresence, motion } from "framer-motion";
import Image, { StaticImageData } from "next/image";
import SideImage1 from "@public/assets/home-page-1.png";
import SideImage2 from "@public/assets/home-page-2.png";
import SideImage3 from "@public/assets/home-page-3.png";
import SideImage4 from "@public/assets/home-page-4.png";
import SideImage5 from "@public/assets/home-page-5.png";
import SideImage6 from "@public/assets/home-page-6.png";
import MobileImage from "@public/assets/mobile-auth-1.png";
import WhiteLogo from "@assets/icons/inawo-logo-2.svg";
import { Toaster } from "react-hot-toast";

type Props = {
  children: React.ReactNode;
};

const SidePageSlide: React.FC<{ image: string | StaticImageData }> = ({
  image,
}) => {
  const sideVariants = {
    initial: {
      opacity: 0.9,
      scale: 1,
    },
    enter: {
      opacity: 1,
      scale: 1.1,
      transition: {
        duration: 5,
        ease: [0.47, 0, 0.75, 0.72],
      },
    },
    exit: {
      opacity: 0,
      scale: 1,
      transition: {
        duration: 0.3,
        ease: [0.9, 0.03, 0.69, 0.22],
      },
    },
  };

  return (
    <motion.div
      initial="initial"
      animate="enter"
      exit="exit"
      variants={sideVariants}
      className="object-cover object-center w-full h-full absolute top-0 transform"
    >
      <Image
        src={image}
        quality={80}
        layout="fill"
        objectFit="cover"
        placeholder="blur"
        alt="Inawo"
        sizes="30vw"
      />
    </motion.div>
  );
};

const AuthLayout: React.FC<Props> = ({ children }) => {
  const [sideImage, setSideImage] = React.useState<number>(1);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setSideImage((sideImage) => (sideImage === 3 ? 1 : sideImage + 1));
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Toaster
        position="bottom-center"
        reverseOrder={true}
        containerStyle={{
          zIndex: "2147483647",
          isolation: "isolate",
          transformStyle: "preserve-3d",
          transform: "translateZ(1000px)",
          bottom: 90,
        }}
        toastOptions={{
          duration: 2000,
          success: {
            style: {
              background: "#376C65",
              border: "0.5px dashed #376C65",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px 50px",
              fontFamily: "SF Pro Text",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "12px",
              textAlign: "center",
              color: "#fff",
              borderRadius: "15px",
              zIndex: "2147483647",
              isolation: "isolate",
              transformStyle: "preserve-3d",
              transform: "trasnslateZ(1000px)",
            },
          },
          error: {
            style: {
              background: "#6C3737",
              border: "0.5px dashed #6C3737",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              padding: "12px 8px",
              fontFamily: "SF Pro Text",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "19px",
              textAlign: "center",
              color: "#fff",
              borderRadius: "15px",
              zIndex: "2147483647",
              isolation: "isolate",
              transformStyle: "preserve-3d",
              transform: "trasnslateZ(1000px)",
            },
          },
        }}
      />
      <div className="xs:h-screen grid grid-cols-8 gap-0 w-screen">
        <div className="h-screen col-span-4 md:col-span-2 overflow-hidden hidden xs:block relative">
          <AnimatePresence exitBeforeEnter>
            {sideImage === 1 && (
              <SidePageSlide image={SideImage1} key={uuidv4()} />
            )}
            {sideImage === 2 && (
              <SidePageSlide image={SideImage2} key={uuidv4()} />
            )}
            {sideImage === 3 && (
              <SidePageSlide image={SideImage3} key={uuidv4()} />
            )}
          </AnimatePresence>
          <div className="bg-auth-gradient h-full w-full absolute top-0" />
          <div className="mx-auto mt-12 flex items-center justify-center">
            <Image
              quality={80}
              priority
              src={WhiteLogo}
              width={92}
              height={27}
              alt="Inawo"
            />
          </div>
        </div>
        <div className="xs:h-screen col-span-full xs:col-span-4 flex flex-col justify-start items-center overflow-y-auto overflow-x-hidden pt-0 xs:pt-12">
          <div className="w-full justify-center items-center relative h-32 overflow-hidden mb-12 flex xs:hidden">
            <div className="z-10">
              <Image
                quality={80}
                priority
                src={WhiteLogo}
                width={92}
                height={27}
                alt="Inawo"
              />
            </div>
            <div className="h-full w-screen absolute top-0">
              <Image
                quality={80}
                layout="fill"
                priority
                objectFit="cover"
                src={MobileImage}
                alt="Inawo"
              />
            </div>
          </div>
          {children}
        </div>
        <div className="h-screen col-span-2 overflow-hidden hidden md:block relative">
          <AnimatePresence exitBeforeEnter>
            {sideImage === 1 && (
              <SidePageSlide image={SideImage4} key={uuidv4()} />
            )}
            {sideImage === 2 && (
              <SidePageSlide image={SideImage5} key={uuidv4()} />
            )}
            {sideImage === 3 && (
              <SidePageSlide image={SideImage6} key={uuidv4()} />
            )}
          </AnimatePresence>
          <div className="bg-auth-gradient h-full w-full absolute top-0" />
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
